<template>
   <div class="forgot-password">
      <div class="forgot-password__main">
         <webstyle-logo class="forgot-password__logo"></webstyle-logo>
         <vue-bootstrap-blocking-notifications></vue-bootstrap-blocking-notifications>

         <div v-if="!blocking_notification_shown">
            <div class="forgot-password__success alert alert-success" v-if="success">
               Your request was accepted. You will receive an e-mail with the
               next step to reset your password. Be sure to
               <strong>check any spam folders</strong> you may have.
               If you have not received the e-mail within five minutes,
               please click the "Contact Us" link and let us know so we
               can help you.
            </div>


            <form @submit.prevent="on_form_submit">
               <div class="forgot-password__entry" v-if="!success">
                  <p class="text-center">Please enter your username</p>
                  
                  <div class="form-group">
                     <div class="input-group">
                     <span class="input-group-text" id="input_username">
                        <i class="fas fa-user"></i>
                     </span>
                     <input type="text" class="form-control" placeholder="Username"
                        aria-label="Username" aria-describedby="input_username"
                        autocomplete="off"
                        autofocus="autofocus" ref="autofocus"
                        v-model="forgot_password.opname"/>
                     </div>
                  </div>

                  <div class="forgot-password__btns">
                     <button class="forgot-password__btn-sm btn btn-primary" type="submit">
                        <i class="fa-solid fa-unlock-keyhole"></i>
                        Reset password
                     </button>
                  </div>
               </div>

               <div class="forgot-password_footer">
                  <button class="forgot-password__btn-md btn btn-primary" type="submit"
                     v-if="!success">
                     <i class="fa-solid fa-unlock-keyhole"></i>
                     Reset password
                  </button>

                  <p class="text-small">
                     <router-link to="/forgot_contact">
                     Click here to contact us</router-link>
                     if you've forgotten your username or require more assistance.
                  </p>
               </div>
            </form>
         </div>
      </div>
   </div>
</template>

<script>
// @ is an alias to /src
'use strict';
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
import ForgotPassword from '@/lib/forgot-password';
import WebstyleLogo from '@/components/webstyle-logo';


export default {
   emits: [],
   name: 'default',

   components: {
      WebstyleLogo,
   },// /components{}

   data (){
      return {
         forgot_password: new ForgotPassword(),
         success: false,
      };
   },// /data()

   computed:{
      ...mapGetters([
      ]),
   }, // /computed{}

   methods: {
      async on_form_submit(){
         this.blocking_notification_show( 'wait', `Please wait...` );

         try{
            await this.forgot_password.submit();
            this.blocking_notification_clear();
            this.success = true;
         }
         catch( err ){
            this.blocking_notification_show( 'problem', err.message );
         }

      },// /on_form_submit()

      ...mapActions([
         'set_page_title',
      ]),
   },// methods{}

   mounted (){
      this.set_page_title( '' );

      /** 
       * Chrome will not autofocus and give an error like...
       * "Autofocus processing was blocked because a document's URL has a fragment '#/..'."
       * So we need to programmatically make sure this field is set focus to.
       */
      this.$refs.autofocus && this.$refs.autofocus.focus();
   },// /mounted()
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.forgot-password__logo
{
   margin-bottom: 1em;;
}

.forgot-password__main
{
   background-color: var(--bs-body-bg);
   padding: 0.5em;
   border-radius: 0.1em;
   max-width: 30em;
   margin: auto;
}

.form-group
{
   margin-bottom: 1em;
}

.text-small
{
   font-size: 0.8em;
}

.forgot-password__btn-md
{
   display: none;
}

/* Small only */
@media screen and (max-width: 767px) 
{
   .forgot-password__btns
   {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1em;
   }

   .forgot-password__btn-sm
   {
      flex-grow: 1;
   }
}

/* Medium and up */
@media screen and (min-width: 768px)
{
   .forgot-password__btn-sm
   {
      display: none;
   }

   .forgot-password_footer
   {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
   }

   .forgot-password__btn-md
   {
      display: inline-block;
      margin-right: 1em;
      flex-grow: 1;
      white-space: nowrap;
   }
}

/* Medium only */
/*@media screen and (min-width: 768px) and (max-width: 991px) {}*/

/* Large and up */
/*@media screen and (min-width: 992px) {}*/

/* Large only */
/*@media screen and (min-width: 992px) and (max-width: 1199px) {}*/

/* X-Large and up */
/*@media screen and (min-width: 1200px) {}*/
</style>
